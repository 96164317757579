<script>
import {
  authMethods,
  authFackMethods,
  notificationMethods,
} from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import VsToast from '@vuesimple/vs-toast';

import appConfig from "@/app.config";

/**
 * Register component
 */
export default {
  page: {
    title: "Register",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      user: {
        username: "",
        password: "",
        confpass: "",
        email: "",
        role: ["artiste"],
        code: ""
      },
      submitted: false,
      regError: null,
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
    };
  },
  validations: {
    user: {
      username: {
        required,
      },
      password: {
        required,
      },
      confpass: {
        required,
        // sameAs: sameAs('user.password')
      }
    },
  },
  mounted() {
    this.user.username = this.$router.currentRoute.query.email;
    this.user.code = this.$router.currentRoute.query.youth;
  },
  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to register the user in with the email, username
    // and password they provided.
    tryToRegisterIn() {
      this.submitted = true;

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToRegister = true;
          // Reset the regError if it existed.
          this.regError = null;
          return (
            this.register({
              email: this.email,
              password: this.password,
            })
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                this.$router.push(
                  this.$route.query.redirectFrom || { name: "home" }
                );
              })
              .catch((error) => {
                this.tryingToRegister = false;
                this.regError = error ? error : "";
                this.isRegisterError = true;
              })
          );
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          const { username, password, confpass } = this.user;
          if (confpass == password) {
            if (username && password) {
              this.user.email = this.user.username;
              this.registeruser(this.user);
            }
          } else {
            VsToast.show({
              title: 'Erreur champs',
              message: 'Les mot de passes ne correspondent pas!',
              variant: 'warning',
              position: "bottom-right"
            })
          }
        }
      }
    },
  },

  computed: {
    ...mapState("authfack", ["status"]),
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-4">
          <div class="card overflow-hidden">
            <div class="bg-primary">
              <div class="text-primary text-center p-4">
                <h5 class="text-white font-size-20">Bienvenu sur YendiPlay</h5>
                  <p class="text-white-50">Ajouter votre compte</p>
                <a href="/" class="logo logo-admin">
                  <img
                    src="@/assets/images/logo.png"
                    height="24"
                    alt="logo"
                  />
                </a>
              </div>
            </div>

            <div class="card-body p-4">
              <div class="p-3">
                <b-alert
                  v-model="registerSuccess"
                  class="mt-3"
                  variant="success"
                  dismissible
                  >Compte créé avec succes.</b-alert
                >

                <b-alert
                  v-model="isRegisterError"
                  class="mt-3"
                  variant="danger"
                  dismissible
                  >{{ regError }}</b-alert
                >

                <div
                  v-if="notification.message"
                  :class="'alert ' + notification.type"
                >
                  {{ notification.message }}
                </div>

                <b-form
                  @submit.prevent="tryToRegisterIn"
                  class="form-horizontal mt-4"
                >
                  <b-form-group
                    id="username-group"
                    label="Nom d'utilisateur"
                    label-for="username"
                    label-class="form-label"
                    class="mb-3"
                  >
                    <b-form-input
                      id="username"
                      v-model="user.username"
                      :disabled="true"
                      type="text"
                      placeholder="Enter votre nom d'utilisateur"
                      :class="{
                        'is-invalid': submitted && $v.user.username.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.user.username.required"
                      class="invalid-feedback"
                    >
                      Le nom d'utilisateur est requis.
                    </div>
                  </b-form-group>

                  <b-form-group
                    id="password-group"
                    label="Mot de passe"
                    label-for="password"
                    label-class="form-label"
                    class="mb-3"
                  >
                    <b-form-input
                      id="password"
                      v-model="user.password"
                      type="password"
                      placeholder="Enter votre mot de passe"
                      :class="{
                        'is-invalid': submitted && $v.user.password.$error,
                      }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.user.password.required"
                      class="invalid-feedback"
                    >
                      Le mot de passe est requis.
                    </div>
                  </b-form-group>
                  <b-form-group
                      id="password-group"
                      label="Confirmer le mot de passe"
                      label-for="password"
                      label-class="form-label"
                      class="mb-3"
                    >
                      <b-form-input
                        id="password"
                        v-model="user.confpass"
                        type="password"
                        placeholder="Entrer à nouveau le mot de passe"
                        :class="{
                          'is-invalid': submitted && $v.user.confpass.$error,
                        }"
                      ></b-form-input>
                      <div
                        v-if="submitted && !$v.user.confpass.required"
                        class="invalid-feedback"
                      >
                        Confirmer le mot de passe.
                      </div>
                      <!-- <div
                        v-if="submitted && !$v.user.confpass.sameAsPassword"
                        class="invalid-feedback"
                      >
                        Le mot de passe ne correspond pas
                      </div> -->
                    </b-form-group>
                    
                  <div class="form-group mb-0 text-center">
                    <div class="col-12 text-end">
                      <b-button type="submit" variant="primary" class="w-md"
                        >S'enregistrer</b-button
                      >
                    </div>
                  </div>
                  
                </b-form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <div class="mt-5 text-center">
            <p>
              Vous avez déjà un compte ?
              <router-link to="/login" class="fw-medium text-primary"
                >Se connecter</router-link
              >
            </p>
            <p class="mb-0">
              ©
              {{ new Date().getFullYear() }} Yendi Play
            </p>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>

<style lang="scss" module></style>
